import React from 'react'
import "../style/components/projects.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import FadeInSection from './utility/FadeInSection';
import { Link } from 'gatsby';

const Projects = (props: { headline: string, hasLines?: boolean, projects: { headline: string, text: string, image: { image: string, alt: string }, link?: { isLinkIntern: boolean, isFileDownload: boolean, text: string, url: string } }[] }) => {

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="custom-swiper-bullet ' + className + '">' + "</span>";
    }
  };

  return (
    <section id="projects">
      <div className='wrapper'>
        <FadeInSection>
          <h2>{props.headline}</h2>
          <div className='projects-container'>
            <Swiper pagination={pagination} breakpoints={{ 768: { slidesPerView: 2.5 } }} spaceBetween={30} modules={[Pagination]} className="custom-swiper">
              {props.projects.map((project, i) =>
                <SwiperSlide key={`services-${i}`} className="project-card swiper-slide">
                  <div className='test'>
                    <img className='project-image' src={project.image.image} alt={project.image.alt} loading='lazy' />
                  </div>
                  <h3 className='project-headline'>{project.headline}</h3>
                  <p className='project-text'>{project.text}</p>
                  {
                    project.link && (project.link.isLinkIntern ? <Link className="project-card-link" to={project.link.url} download={project.link.isFileDownload}>{project.link.text}</Link> : <a className="project-card-link" href={project.link.url} target="_blank" download={project.link.isFileDownload}>{project.link.text}</a>)
                  }
                </SwiperSlide>
              )
              }
            </Swiper>
          </div>
        </FadeInSection>
      </div>
    </section>
  )
}

export default Projects