import * as React from "react"
import { Helmet } from 'react-helmet';
import Contact from "../components/Contact";
import Hero from "../components/Hero"
import Projects from "../components/Projects";
import Services from "../components/Services";
import Footer from "../components/utility/Footer";
import Values from "../components/Values";
import Why from "../components/Why";
import Workflow from "../components/Workflow";
import "../style/pages/index.scss";
import heroImage from "../images/haus-07.jpg";
import whyImage from "../images/haus-04.jpg";
import lenseIcon from "../images/lens.svg";
import responsabilityIcon from "../images/responsability.svg";
import communicationIcon from "../images/communication.svg";
import reliableIcon from "../images/reliable.svg";
import economyIcon from "../images/economy.svg";
import teamworkIcon from "../images/teamwork.svg";
import qualityIcon from "../images/quality.svg";
import Navbar from "../components/utility/Navbar";
import projectImg01 from "../images/projekt-01.jpg";
import projectImg02 from "../images/projekt-02.jpg";
import projectImg03 from "../images/projekt-03.jpg";
import { jobsData } from "../utility/jobsData";

const heroContent = {
  image: heroImage,
  alt: "Ein Haus mit großer Glasfassade",
  headline: "Häuser aus Holz. <br />Für Menschen mit Ideen.",
  text: "Wir möchten nicht nur Gebäude zum drin wohnen bauen, sondern einen neuen Lebensmittelpunkt zum Wohlfühlen schaffen."
}

const valuesData = [
  {
    icon: lenseIcon,
    alt: "Ein Lupe",
    headline: "Kundenorientierung",
    text: "Ihre Wünsche sind unser gemeinsames Ziel. Mit klarer Kommunikation, mit Sorgfalt, Know-How und Erfahrung werden wir es zusammen erreichen."
  },
  {
    icon: responsabilityIcon,
    alt: "Eine Schriftrolle",
    headline: "Verantwortung",
    text: "Wir arbeiten professionell und zielorientiert, suchen nach Lösungen und stehen zu unseren Schwächen. Glaubwürdigkeit ist uns wichtig."
  },
  {
    icon: communicationIcon,
    alt: "Sprechblasen ohne Inhalt",
    headline: "Kommunikation",
    text: "Wir sprechen offen, direkt und ehrlich miteinander und geben uns konstruktives Feedback. Respekt, Toleranz und Fairness sind die Grundlage für gute Zusammenarbeit, die uns sehr am Herzen liegt."
  },
  {
    icon: reliableIcon,
    alt: "Ein Burgturm",
    headline: "Zuverlässigkeit",
    text: "Kunden und Geschäftspartner können sich auf uns verlassen. Sollte doch Mal etwas schief gehen, suchen wir die Chance zu lernen, uns zu verbessern und zu wachsen."
  },
  {
    icon: economyIcon,
    alt: "Ein Prozentzeichen",
    headline: "Wirtschaftlichkeit",
    text: "Schwäbische Sparsamkeit ist beim Bauen genauso angebracht wie an den richtigen Stellen gut zu investieren. Wir arbeiten fleißig, zielstrebig, sorgfältig und effizient. Wir sparen Kosten indem wir dank unserer Strukturen viel weniger Aufwand für Marketing, Controlling und Verwaltung benötigen als viele größere Mitbewerber."
  },
  {
    icon: teamworkIcon,
    alt: "Zwei Personen",
    headline: "Teamgeist",
    text: "Wir unterstützen uns im Team und leben ein kollegiales Miteinander, agieren gelassen, habe Freude bei der Arbeit und strahlen Optimismus aus."
  },
  {
    icon: qualityIcon,
    alt: "Eine abstrakte Zeichung eines Diamanten",
    headline: "Qualität",
    text: "Durch erstklassige Aus- und Weiterbildung unserer Mitarbeiter, durch saubere und umsichtige Planung auf dem neuesten Stand der Technik und in Zusammenarbeit mit anderen Fachleuten gewährleisten wir die Ausführung unserer Leistungen auf sehr hohem Qualitäts-Niveau. Wo Menschen arbeiten, passieren auch Fehler. Mit diesen gehen wir konstruktiv um (,sind gut versichert ;-) und haben noch jedes Problem in den Griff bekommen."
  }
]

const whyData = {
  isLeft: true,
  alt: "Ein freistehendes Haus mit großem Garten",
  headline: "Warum ein Holzbau von der<br />Zimmerei Müller?",
  text: "Tatsächlich gibt es gerade in Süddeutschland eine Menge guter Zimmerleute. Vielleicht sind wir aber gerade für Ihr Anliegen genau richtig aufgestellt? Durch unsere gute Vernetzung sind wir auch als eher kleiner Betrieb in der Lage, Projekte umzusetzen, die sonst eher größeren vorbehalten sind. Und das mit den Vorteilen, die die Kundschaft schon Jahrzehnte an uns schätzt: Ein bodenständiger, familiengeführter, regionaler Handwerksbetrieb, der mit Herz und Verstand und vollem Einsatz, der Tradition und dem guten Ruf verpflichtet für Zuverlässigkeit, kurze Dienstwege und den Qualitätsanspruch eines schwäbischen Zimmerers steht.",
  list: [
    {
      iconIsRising: true,
      text: "Inhabergeführtes Familienunternehmen"
    },
    {
      iconIsRising: false,
      text: "Wohnhäuser in Holztafelbauweise, mit Massivholz- und Ingenieurbauelementen"
    },
    {
      iconIsRising: true,
      text: "Verwendung ökologischer und wohngesunder Bau- und Dämmstoffe"
    },
    {
      iconIsRising: false,
      text: "Eigenes Ingenieurbüro (Statik)"
    }
  ]
}

const projectsData = [
  {
    image: {
      image: projectImg01,
      alt: "Ein Einfamilienhaus mit kleinem Garten"
    },
    headline: "Wohnhaus in Kaisersbach",
    text: "Energieeffizienz KfW 40 plus."
  },
  {
    image: {
      image: projectImg02,
      alt: "Ein mehrstöckiges Gemeindehaus"
    },
    headline: "Gemeindehaus Kaisersbach",
    text: "Holzbau als Ortskernsanierung."
  },
  {
    image: {
      image: projectImg03,
      alt: "Reihenhäuser aus Holz"
    },
    headline: "Cottastraße",
    text: "Holzbau im DG Gebäudeklasse 5",
  }
]

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet title="Zimmerei Müller - Häuser aus Holz">
        <meta property="og:title" content="Zimmerei Müller - Häuser aus Holz" />
        <meta
          property="og:description"
          content="Wir möchten Ihnen nicht nur ein Haus zum Wohnen bauen, sondern einen neuen und wertvollen Lebensmittelpunkt schaffen."
        />
        <meta property="og:url" content="https://holzbaumueller.de" />
        <meta
          name="description"
          content="Wir möchten Ihnen nicht nur ein Haus zum Wohnen bauen, sondern einen neuen und wertvollen Lebensmittelpunkt schaffen."
        />
      </Helmet>
      <Navbar />
      <main>
        <Hero headline={heroContent.headline} text={heroContent.text} image={heroContent.image} alt={heroContent.alt} />
        <Why isImageLeft={whyData.isLeft} headline={whyData.headline} text={whyData.text} list={whyData.list} image={whyImage} alt={whyData.alt} />
        <Services hasLines={true} />
        <Projects headline="Unsere Projekte" hasLines={true} projects={projectsData} />
        <Workflow />
        <Values headline="Was uns wichtig ist" values={valuesData} showLines={true} />
        <Projects headline={jobsData.headline} hasLines={true} projects={jobsData.data} />
        <Contact />
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
