import React from 'react'
import "../style/components/workflow.scss";
import budgetIcon from "../images/budget.svg";
import wishIcon from "../images/wish.svg";
import planIcon from "../images/plan.svg";
import house01 from "../images/haus-01.jpg";
import house02 from "../images/haus-02.jpg";
import house03 from "../images/haus-03.jpg";
import house04 from "../images/haus-04.jpg";
import house05 from "../images/haus-05.jpg";
import house06 from "../images/haus-06.jpg";
import house07 from "../images/haus-07.jpg";
import house08 from "../images/haus-08.jpg";
import NavigationSwiper from './utility/NavigationSwiper';
import FadeInSection from './utility/FadeInSection';

const workflowsData = [
  {
    icon: budgetIcon,
    alt: "Ein Quadrat mit den 4 Grundrechenarten",
    headline: "Aufgabe",
    text: "Wir beraten Sie wenn es darum geht, wie Ihr Bauprojekt am Ende aussehen kann, welche Aufgaben zu lösen sind, wo die Prioritäten und wo die Grenzen liegen."
  },
  {
    icon: wishIcon,
    alt: "Ein Blatt mit angedeutetem Text",
    headline: "Wünsche",
    text: "Wir suchen nach Lösungen, um Ihre Wünsche in die Tat umzusetzen und die richtigen Partner um als Team in vertrauensvoller Zusammenarbeit richtig gute Arbeit zu leisten."
  },
  {
    icon: planIcon,
    alt: "Ein Quadrat mit einer leuchtenden Aura",
    headline: "Planung",
    text: "Auf dieser Basis erarbeiten wir die tatsächliche Baugesuchs- Ausführungs- und Detailplaung in laufender Abstimmung mit Ihnen und unseren Partnern und können auf dieser Basis auch exakt kalkulieren und Preise garantieren."
  }
]

const swiperImages = [{ image: house01, alt: "Ein mehrstöckiges Holzhaus mit großem Garten" }, { image: house02, alt: "Ein einfamilien Haus" }, { image: house03, alt: "Ein einfamilien Haus mit Garage" }, { image: house04, alt: "Ein freistehendes Haus mit großem Garten" }, { image: house05, alt: "Ein freistehendes Haus mit großem Garten und Garage" }, { image: house06, alt: "Reihenhäuser" }, { image: house07, alt: "Ein Haus mit großer Glasfassade" }, { image: house08, alt: "Ein freistehendes Haus mit großem Garten und Garage" }]

const Workflow = () => {

  return (
    <section id="workflows">
      <div className='wrapper'>
        <FadeInSection>
          <h2>Sie stellen es sich vor.<br /> Wir stellen es her.</h2>
          <p className='workflow-content-text'>Für welche Ausführung Sie sich auch immer entscheiden, wir bauen Ihnen das passende Holzhaus. Ob Holzrahmenbau, Massivholzbau, Ingenieurholzbau, Fachwerk oder Lehmbau. So kann und wird auch Ihr Eigenheim ein Unikat werden, wie Sie es mögen und zu Ihren Wünschen und Möglichkeiten passt.</p>
        </FadeInSection>
        <FadeInSection>
          <div className='workflow-card-container'>
            <hr className='horizontal-line' />
            {workflowsData.map((workflow, i) =>
              <div key={workflow.headline} className='workflow-card'>
                <div className='workflow-icon-container'>
                  <img className='workflow-icon' src={workflow.icon} alt={workflow.alt} />
                  {i === 1 && <hr className='cross-line' />}
                </div>
                <h3 className='workflow-headline'>{workflow.headline}</h3>
                <p className='workflow-text'>{workflow.text}</p>
              </div>
            )
            }
          </div>
        </FadeInSection>
        <FadeInSection>
          <div className='workflow-swiper-container'>
            <NavigationSwiper images={swiperImages} />
            <hr className='topright-line' />
            <hr className='bottomleft-line' />
          </div>
        </FadeInSection>
      </div>
    </section>
  )
}

export default Workflow