import jobImg01 from "../images/job-01.jpg";
import jobImg02 from "../images/job-02.jpg";
import jobImg03 from "../images/job-03.jpg";
import jobImg04 from "../images/job-04.jpg";
import meisterPdf from "../static/meister.pdf";
import fachPdf from "../static/facharbeiter.pdf";
import ausbildungPdf from "../static/ausbildung.pdf";
import vorarbeiterPdf from "../static/vorarbeiter.pdf";

const jobsData = {
  headline: "Stellenangebote für Zimmerleute",
  data: [
    {
      image: {
        image: jobImg01,
        alt: "4 Männer beim Aufbau eines Holzhauses"
      },
      headline: "Meister",
      text: "Als Meister kannst Du Dir natürlich aussuchen, wo und wie Du arbeiten willst - bei uns solltest du Spaß an verschiedensten Herausforderungen haben, ein gemischtes Team führen und Hand in Hand mit dem Chef zusammenarbeiten können. Wir akzeptieren, dass Du das so machst, wie es Dein Stil ist !",
      link: {
        isLinkIntern: false, //File downloads are alway extern
        isFileDownload: true,
        text: "Mehr erfahren",
        url: meisterPdf,
      }
    },
    {
      image: {
        image: jobImg02,
        alt: "Aufbau aus Holzklötzen"
      },
      headline: "Vorarbeiter",
      text: "Mache bei uns einen tollen Job ! Du bekommst eine interessante Aufgabe vom Chef, die Arbeitsvorbereitung aus unserem eigenen Büro und wir überlegen gemeinsam, wieviele Jungs auf der Baustelle - und los geht's !",
      link: {
        isLinkIntern: false, //File downloads are alway extern
        isFileDownload: true,
        text: "Mehr erfahren",
        url: vorarbeiterPdf,
      }
    },
    {
      image: {
        image: jobImg03,
        alt: "Eine Treppe aus Holzklötzen"
      },
      headline: "Facharbeiter",
      text: "Auch wenn Du gerade erst ausgelernt hast, dafür aber topfit und voller Tatendrang bist, kannst Du bei uns gleich richtig gut loslegen !",
      link: {
        isLinkIntern: false, //File downloads are alway extern
        isFileDownload: true,
        text: "Mehr erfahren",
        url: fachPdf,
      }
    },
    {
      image: {
        image: jobImg04,
        alt: "Ein Turm aus Holzklötzen",
      },
      headline: "Ausbildung",
      text: "Wir hoffen, dass Du aus Begeisterung und Leidenschaft für Holz und Handwerk zu uns kommst - dann wird das bestimmt eine super Sache ! Bewirb dich einfach mal !",
      link: {
        isLinkIntern: false, //File downloads are alway extern
        isFileDownload: true,
        text: "Mehr erfahren",
        url: ausbildungPdf,
      }
    }
  ]
}

export { jobsData }