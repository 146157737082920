import { Link } from 'gatsby';
import React from 'react'
import { serviceData } from '../data/service';
import "../style/components/services.scss";
import FadeInSection from './utility/FadeInSection';

const Services = (props: { hasLines?: boolean }) => {
  return (
    <section id="services">
      <div className='wrapper'>
        <FadeInSection>
          <h2>Unsere Leistungen</h2>
          <div className='services-grid'>
            {
              serviceData.map((service, i) =>
                <div className={service.isPlaceholder ? "service-card-container placeholder" : "service-card-container"} key={`service-${i}`}>
                  {!service.isPlaceholder &&
                    <Link className='service-card' to={`/leistungen?service=${service.selection}`}>
                      <div className='service-card-icon-container'>
                        <img className='service-icon' src={service.icon} alt={service.alt} />
                        {props.hasLines && i === 1 && <hr className='horizontal-line' />}
                      </div>
                      <span className='service-text'>{service.text}</span>
                    </Link>
                  }

                </div>
              )
            }
          </div>
        </FadeInSection>
      </div>
    </section>
  )
}

export default Services